import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import {
  KEY_IS_EMAIL_VERIFIED,
  authInstance,
  firebaseAuth,
  sendEmailVerification,
} from "@utils/firebase";
import Header from "@components/Header";
import {
  ROUTE_AUTH_VERIFY_EMAIL,
  ROUTE_DASHBOARD,
  ROUTE_REGISTER,
} from "@constants/routes";

function Login() {
  const { search } = useLocation();
  const [isOperating, setIsOperating] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const isEmailVerified =
    new URLSearchParams(search).get(KEY_IS_EMAIL_VERIFIED) === "true";

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  async function onForgotPassword() {
    if (!emailRef.current?.value) {
      toast.error("Email is required");
      return;
    }

    const toastId = toast.loading("Sending password reset email...");

    try {
      await firebaseAuth.sendPasswordResetEmail(
        authInstance,
        emailRef.current!.value
      );
      toast.update(toastId, {
        render: "Password reset email sent!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      toast.update(toastId, {
        render: (error as Error).message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }
  }

  const signIn = async () => {
    if (isOperating) {
      return;
    }

    if (!emailRef.current?.value) {
      toast.error("Email is required");
      return;
    }

    if (!passwordRef.current?.value) {
      toast.error("Password is required");
      return;
    }

    const toastId = toast.loading("Logging in...");
    setIsOperating(true);

    try {
      const userCredential = await firebaseAuth.signInWithEmailAndPassword(
        authInstance,
        emailRef.current!.value,
        passwordRef.current!.value
      );

      const { emailVerified } = userCredential.user;
      if (!emailVerified) {
        toast.dismiss(toastId);

        try {
          await sendEmailVerification(userCredential.user);
          navigate(ROUTE_AUTH_VERIFY_EMAIL, { replace: true });
        } catch (error) {
          toast.error((error as Error).message);
        }

        return;
      }

      toast.update(toastId, {
        render: "You are now logged in!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      navigate(ROUTE_DASHBOARD, { replace: true });
    } catch (error) {
      toast.update(toastId, {
        render: (error as Error).message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    } finally {
      setIsOperating(false);
    }
  };

  return (
    <div className="login-page h-full">
      <Header />
      <div className="h-[calc(100%-73px)]">
        <div className="flex h-full w-full items-center justify-center">
          <div className="w-full max-w-lg">
            <form
              className="mb-4 rounded bg-white px-8 pt-6 pb-8 drop-shadow-md"
              onSubmit={onSubmit}
            >
              {isEmailVerified && (
                <div className="mb-4 rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-green-800">
                        Email is verified successfully!
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                  id="email"
                  type="text"
                  placeholder="Enter your email"
                  ref={emailRef}
                />
              </div>
              <div className="mb-6">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                  id="password"
                  type="password"
                  placeholder="******************"
                  ref={passwordRef}
                />
                <div className="text-right">
                  <button className="text-xs" onClick={onForgotPassword}>
                    Forgot Password?
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                  type="submit"
                  onClick={() => {
                    signIn();
                  }}
                >
                  Sign In
                </button>
                <button
                  className="text-md inline-block align-baseline font-bold text-blue-500 hover:text-blue-800"
                  onClick={() => {
                    navigate(ROUTE_REGISTER);
                  }}
                >
                  Sign Up?
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
