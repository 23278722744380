// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, reauthenticateWithCredential } from "firebase/auth";
import * as firebaseAuth from "firebase/auth";
import { ROUTE_LOGIN } from "@constants/routes";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-UIS_2qASH86fnPs7zf7Oa3IRMtdwPKw",
  authDomain: "copicake.firebaseapp.com",
  projectId: "copicake",
  storageBucket: "copicake.appspot.com",
  messagingSenderId: "801657751604",
  appId: "1:801657751604:web:2fd3bb4bd842f432dda261",
  measurementId: "G-27400960CJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const authInstance = getAuth(app);

const KEY_IS_EMAIL_VERIFIED = "is_email_verified";

async function sendEmailVerification(user: firebaseAuth.User) {
  const actionCodeSettings = {
    url: `${process.env.REACT_APP_SERVER_ENDPOINT}${ROUTE_LOGIN}?${KEY_IS_EMAIL_VERIFIED}=true`,
    actionCodeSettings: true,
  };

  await firebaseAuth.sendEmailVerification(user, actionCodeSettings);
}

export {
  authInstance,
  firebaseAuth,
  sendEmailVerification,
  reauthenticateWithCredential,
  KEY_IS_EMAIL_VERIFIED,
};
